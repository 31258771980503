import React from "react";
import { Replace } from "../../utils/types";

interface ButtonProps {
  variant?: "primary" | "danger";
}

export const Button: React.FC<
  Replace<React.ButtonHTMLAttributes<HTMLButtonElement>, ButtonProps>
> = ({ children, variant = "primary", ...rest }) => {
  return (
    <button {...rest} className={`button variant-${variant}`}>
      {children}
    </button>
  );
};
