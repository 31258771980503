module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["de"],"defaultLanguage":"de","siteUrl":"https://nftgarden.net","trailingSlash":"always","i18nextOptions":{"fallbackLng":"de","returnNull":false,"debug":false,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
