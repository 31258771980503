import React, { useEffect, useMemo } from "react";
import { Button } from "./common/Button";
import { useCookies } from "react-cookie";

const COOKIE_CONSENT = "COOKIE_CONSENT";

export const CookieBanner: React.FC = () => {
  const [cookies, setCookie] = useCookies<
    typeof COOKIE_CONSENT,
    { [COOKIE_CONSENT]?: "true" | "false" }
  >([COOKIE_CONSENT]);

  const status = useMemo<boolean | null>(() => {
    if (cookies[COOKIE_CONSENT] === undefined) return null;
    return cookies[COOKIE_CONSENT] === "true";
  }, [cookies]);

  const handleClick = (status: boolean) => {
    setCookie(COOKIE_CONSENT, String(status));
  };

  useEffect(() => {
    if (status !== true) return;
    // TODO activate tracking
  }, [status]);

  if (status !== null) return null;

  return (
    <div className="cookie-banner">
      <div className="cookie-banner__wrapper">
        <div className="cookie-banner__information">
          <p>
            Sie können uns konktaktieren über folgendes Formular, per E-Mail
            oder uns direkt anrufen unter dieser Nummer. Möchten Sie, dass wir
            Sie zurückrufen? Dann teilen Sie uns bitte Ihren Namen, Ihre Nummer
            mit, unter der wir Sie erreichen können und zu welcher Zeit.
          </p>
          <ul>
            <li>
              <a href="/legal">Impressum</a>
            </li>
            <li>
              <a href="/privacy">Datenschutz</a>
            </li>
          </ul>
        </div>
        <div className="cookie-banner__buttons">
          <Button onClick={() => handleClick(true)}>Akzeptieren</Button>
          <Button onClick={() => handleClick(false)} variant="danger">
            Ablehnen
          </Button>
        </div>
      </div>
    </div>
  );
};
