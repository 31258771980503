import React from "react";
import { GatsbyBrowser } from "gatsby";
import { Layout } from "./src/components/Layout";

import "destyle.css/destyle.min.css";
import "@fontsource/dela-gothic-one";
import "@fontsource/monda";
import "./src/styles/index.scss";
import "./src/styles/pages.scss";
import "./src/styles/components.scss";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};
