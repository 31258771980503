import React, { ReactNode } from "react";
import { CookieBanner } from "./CookieBanner";
import { CookiesProvider } from "react-cookie";

export const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <CookiesProvider>
      <div className="layout">
        <CookieBanner />
        {children}
      </div>
    </CookiesProvider>
  );
};
